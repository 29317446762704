import React from 'react';
import Layout from '../components/Layout';
import TagManager from '../components/TagManager';

class NotFoundPage extends React.Component {
    render() {
        return (
            <Layout>
                <TagManager />
                <noscript>
                    <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-MCXNGPD" height="0" width="0" style={{ display: "none", visibility: "hidden" }}>
                    </iframe>
                </noscript>
                <h1>Not Found</h1>
                <p>You just hit a page that doesn&#39;t exist...</p>
            </Layout>
        );
    }
}

export default NotFoundPage;
